import React from 'react';
import ScreenMessage from './ScreenMessage';
import ScreenLogin from './ScreenLogin';
import PropTypes from 'prop-types';

export default function ContactForm(props) {
	const form = props.form;
	const token = props.token;
	
	return (
		<>
			{token && <ScreenMessage form={form} token={token} onError={props.onError}/>}
			{!token && <ScreenLogin form={form} onError={props.onError}/>}
		</>
	);
}

ContactForm.propTypes = {
	form: PropTypes.object.isRequired,
	token: PropTypes.string,
	onError: PropTypes.func.isRequired
}