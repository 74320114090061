import React, {useEffect, useState} from 'react';
import {Typography, useTheme} from '@mui/material';
import YouTubeIcon from '@mui/icons-material/YouTube';
import {LoadingButton} from '@mui/lab';
import PropTypes from 'prop-types';

export default function ScreenLogin({form, onError}) {
	const theme = useTheme();
	const [loading, setLoading] = useState(false);
	
	const onClick = () => {
		if (loading) {
			return;
		}
		setLoading(true);
	}
	
	useEffect(() => {
		if (!loading) {
			return;
		}
		
		(async () => {
			try {
				const url = `${process.env.REACT_APP_API_SERVER}/youtube/auth-url?formId=${encodeURIComponent(form._id)}`;
				const response = await fetch(url);
				const data = await response.json();
				
				if (data.error) {
					setLoading(false);
					return onError(data.error);
				} else {
					window.location.href = data.url;
				}
			} catch (e) {
				console.log(e);
				setLoading(false);
				return onError('Something went wrong. Please try again later.');
			}
		})();
	}, [loading, form._id, onError]);
	
	return (
		<>
			<Typography variant={'h5'} textAlign={'center'}>Confirm your identity by logging in with your YouTube account</Typography>
			<LoadingButton
				loading={loading}
				color='secondary'
				variant='contained'
				startIcon={<YouTubeIcon/>}
				style={{margin: theme.spacing(5)}}
				onClick={onClick}
			>Login with YouTube</LoadingButton>
		</>
	);
}

ScreenLogin.propTypes = {
	form: PropTypes.object.isRequired,
	onError: PropTypes.func.isRequired
}
