import React, {useState} from 'react';
import {Box, Button, Paper, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {LoadingButton} from '@mui/lab';
import {Controller, useForm} from 'react-hook-form';
import {FormContainer, TextFieldElement} from 'react-hook-form-mui'
import storage from '../utils/storage';
import {useTheme} from '@emotion/react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import {MuiTelInput} from 'mui-tel-input';

export default function ScreenMessage(props) {
	const form = props.form;
	const token = props.token;
	
	const [loading, setLoading] = useState(false);
	const theme = useTheme();
	const navigate = useNavigate();
	
	const formContext = useForm({
		defaultValues: {
			firstName: '',
			middleName: '',
			lastName: '',
			email: '',
			phone: '',
			message: ''
		}
	})
	
	const handleSubmit = (formData) => {
		props.onError(null);
		setLoading(true);
		
		(async () => {
			try {
				const response = await fetch(`${process.env.REACT_APP_API_SERVER}/form/${encodeURIComponent(form._id)}/message`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({
						token,
						form: formData
					})
				});
				setLoading(false);
				
				const data = await response.json();
				if (data.error) {
					return props.onError(data.error);
				} else {
					return navigate('/thank-you');
				}
				
			} catch (e) {
				console.log(e);
				setLoading(false);
				return props.onError('Something went wrong. Please try again later.');
			}
		})();
	}
	
	const onCancel = () => {
		storage.removeItem('token');
		window.location.reload();
	};
	
	return (
		<Paper>
			<Box p={4}>
				<FormContainer className={'form'} onSuccess={handleSubmit} formContext={formContext}>
					<Grid container spacing={2} alignItems={'top'}>
						{form.description && <Grid size={12}><Typography textAlign={'center'}>{form.description}</Typography></Grid>}
						<Grid size={4}>
							<TextFieldElement
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='firstName'
								label='First Name'
								name='firstName'
								autoComplete='firstName'
								autoFocus
							/>
						</Grid>
						<Grid size={4}>
							<TextFieldElement
								variant='outlined'
								margin='normal'
								fullWidth
								id='middleName'
								label='Middle Name'
								name='middleName'
								autoComplete='middleName'
							/>
						</Grid>
						<Grid size={4}>
							<TextFieldElement
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='lastName'
								label='Last Name'
								name='lastName'
								autoComplete='lastName'
							/>
						</Grid>
						<Grid size={6}>
							<TextFieldElement
								variant='outlined'
								margin='normal'
								required
								fullWidth
								id='email'
								label='Email Address'
								name='email'
								autoComplete='email'
								type={'email'}
							/>
						</Grid>
						<Grid size={6}>
							<Controller
								name={'phone'}
								control={formContext.control}
								render={({field, fieldState}) => (
									<MuiTelInput
										{...field}
										variant='outlined'
										margin='normal'
										fullWidth
										label='Phone Number'
										autoComplete='phone'
										defaultCountry={'US'}
										value={field.value}
										onChange={field.onChange}
										error={!!fieldState.error}
										helperText={fieldState.error ? fieldState.error.message : null}
									/>
								)}/>
						</Grid>
						<Grid size={12}>
							<TextFieldElement
								variant='outlined'
								margin='normal'
								fullWidth
								multiline
								minRows={3}
								maxRows={10}
								required
								id='message'
								label='Your Message'
								name='message'
								rules={{
									minLength: {
										value: 20,
										message: 'Your message is too short'
									},
									maxLength: {
										value: 5000,
										message: 'Maximum message length is 5000 characters'
									},
								}}
							/>
						</Grid>
						<Grid size={12} style={{textAlign: 'center'}}>
							<LoadingButton
								loading={loading}
								color='primary'
								variant='contained'
								type={'submit'}
							>Submit</LoadingButton>
							
							<Button
								style={{marginLeft: theme.spacing(1)}}
								color={'error'}
								variant='contained'
								onClick={onCancel}>Cancel</Button>
						</Grid>
					</Grid>
				</FormContainer>
			</Box>
		</Paper>
	);
}

ScreenMessage.propTypes = {
	form: PropTypes.object.isRequired,
	token: PropTypes.string.isRequired,
	onError: PropTypes.func.isRequired
}
