export default class TtlStorage {
	
	#storage;
	#ttl;
	
	constructor(storage, defaultTtl = 3600) {
		this.#storage = storage;
		this.#ttl = defaultTtl;
	}
	
	setItem(key, value, ttl) {
		const now = new Date();
		ttl = ttl || this.#ttl;
		const item = {
			value: value,
			expiry: now.getTime() + ttl,
		};
		this.#storage.setItem(key, JSON.stringify(item));
	}
	
	getItem(key) {
		const itemStr = this.#storage.getItem(key);
		if (!itemStr) {
			return null;
		}
		
		const item = JSON.parse(itemStr);
		const now = new Date();
		
		if (now.getTime() > item.expiry) {
			this.#storage.removeItem(key);
			return null;
		}
		return item.value;
	}
	
	removeItem(key) {
		return this.#storage.removeItem(key);
	}
}