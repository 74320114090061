import React, {useEffect, useState} from 'react';
import {CircularProgress, Typography} from '@mui/material';
import {useParams} from 'react-router-dom';
import ContactForm from '../components/ContactForm';
import storage from '../utils/storage';

export default function ContactFormPage() {
	const {formId} = useParams();
	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [form, setForm] = useState(null);
	
	const token = storage.getItem('token');
	
	useEffect(() => {
		(async () => {
			try {
				const url = `${process.env.REACT_APP_API_SERVER}/form/${formId}`;
				const response = await fetch(url);
				const form = await response.json();
				
				if (form.error) {
					setError(form.error);
				} else {
					setForm(form);
				}
			} catch (e) {
				console.log(e);
				setError('Something went wrong. Please try again later');
			}
			setLoading(false);
		})();
	}, [formId]);
	
	return (
		<>
			{loading && <CircularProgress color={'secondary'}/>}
			{form && <ContactForm form={form} token={token} onError={setError}/>}
			{error && <Typography variant={'h5'} color={'secondary'} mt={5} textAlign={'center'}>{error}</Typography>}
		</>
	);
}

ContactForm.propTypes = {}
