import './App.css';
import {Container, createTheme, CssBaseline} from '@mui/material';
import {indigo, pink} from '@mui/material/colors';
import {ThemeProvider} from '@emotion/react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import ContactFormPage from './pages/ContactFormPage';
import YoutubeCallbackPage from './pages/YoutubeCallbackPage';
import ThankYouPage from './pages/ThankYouPage';

const theme = createTheme({
	palette: {
		primary: indigo,
		secondary: pink,
		success: {
			main: '#4caf50'
		},
		background: '#fafafa'
	}
});

function App() {
	return <ThemeProvider theme={theme}>
		<CssBaseline/>
		<Container component='main' maxWidth='md'>
			<div id="app-root">
				<BrowserRouter>
					<Routes>
						<Route path={'/form/:formId'} element={<ContactFormPage/>}/>
						<Route path={'/thank-you'} element={<ThankYouPage/>}/>
						<Route path={'/youtube/callback'} element={<YoutubeCallbackPage/>}/>
					</Routes>
				</BrowserRouter>
			</div>
		</Container>
	</ThemeProvider>;
}

export default App;
